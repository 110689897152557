.w1vbzw5y{position:relative;max-width:343px;}@media only screen and (min-width:481px){.w1vbzw5y{margin:0;}}
.s1op7fgu{width:100%;max-height:100%;display:blocK;}
.b5rhvr7{position:absolute;width:8.7%;top:48.5%;left:58.3%;}
.cpthjb8{position:absolute;width:33%;top:46%;left:63%;-webkit-animation:comb-cpthjb8 linear 3.8s infinite;animation:comb-cpthjb8 linear 3.8s infinite;}@-webkit-keyframes comb-cpthjb8{25%{-webkit-transform:translate(-10px,-5px) rotate(-5deg);-ms-transform:translate(-10px,-5px) rotate(-5deg);transform:translate(-10px,-5px) rotate(-5deg);}35%{-webkit-transform:translate(-10px,-20px) rotate(10deg);-ms-transform:translate(-10px,-20px) rotate(10deg);transform:translate(-10px,-20px) rotate(10deg);}55%{-webkit-transform:translate(10px,-10px) rotate(0);-ms-transform:translate(10px,-10px) rotate(0);transform:translate(10px,-10px) rotate(0);}65%{-webkit-transform:translate(0,-10px) rotate(0);-ms-transform:translate(0,-10px) rotate(0);transform:translate(0,-10px) rotate(0);}80%{-webkit-transform:translate(-10px,-5px) rotate(-10deg);-ms-transform:translate(-10px,-5px) rotate(-10deg);transform:translate(-10px,-5px) rotate(-10deg);}}@keyframes comb-cpthjb8{25%{-webkit-transform:translate(-10px,-5px) rotate(-5deg);-ms-transform:translate(-10px,-5px) rotate(-5deg);transform:translate(-10px,-5px) rotate(-5deg);}35%{-webkit-transform:translate(-10px,-20px) rotate(10deg);-ms-transform:translate(-10px,-20px) rotate(10deg);transform:translate(-10px,-20px) rotate(10deg);}55%{-webkit-transform:translate(10px,-10px) rotate(0);-ms-transform:translate(10px,-10px) rotate(0);transform:translate(10px,-10px) rotate(0);}65%{-webkit-transform:translate(0,-10px) rotate(0);-ms-transform:translate(0,-10px) rotate(0);transform:translate(0,-10px) rotate(0);}80%{-webkit-transform:translate(-10px,-5px) rotate(-10deg);-ms-transform:translate(-10px,-5px) rotate(-10deg);transform:translate(-10px,-5px) rotate(-10deg);}}
.d14ktdw1{position:absolute;width:33.5%;top:25%;left:0;-webkit-animation:dryer-d14ktdw1 linear 3s infinite;animation:dryer-d14ktdw1 linear 3s infinite;}@-webkit-keyframes dryer-d14ktdw1{25%{-webkit-transform:translate(-20px,-10px) rotate(-7.5deg);-ms-transform:translate(-20px,-10px) rotate(-7.5deg);transform:translate(-20px,-10px) rotate(-7.5deg);}50%{-webkit-transform:translate(-25px,40px) rotate(-15deg);-ms-transform:translate(-25px,40px) rotate(-15deg);transform:translate(-25px,40px) rotate(-15deg);}75%{-webkit-transform:translate(5px,-5px) rotate(5deg);-ms-transform:translate(5px,-5px) rotate(5deg);transform:translate(5px,-5px) rotate(5deg);}}@keyframes dryer-d14ktdw1{25%{-webkit-transform:translate(-20px,-10px) rotate(-7.5deg);-ms-transform:translate(-20px,-10px) rotate(-7.5deg);transform:translate(-20px,-10px) rotate(-7.5deg);}50%{-webkit-transform:translate(-25px,40px) rotate(-15deg);-ms-transform:translate(-25px,40px) rotate(-15deg);transform:translate(-25px,40px) rotate(-15deg);}75%{-webkit-transform:translate(5px,-5px) rotate(5deg);-ms-transform:translate(5px,-5px) rotate(5deg);transform:translate(5px,-5px) rotate(5deg);}}
.so2mtc8{position:absolute;top:19%;left:63%;-webkit-animation:scissors-so2mtc8 linear 2s infinite;animation:scissors-so2mtc8 linear 2s infinite;-webkit-transform-origin:34px 57px;-ms-transform-origin:34px 57px;transform-origin:34px 57px;}@-webkit-keyframes scissors-so2mtc8{15%{-webkit-transform:translate(0px,15px) rotate(5deg);-ms-transform:translate(0px,15px) rotate(5deg);transform:translate(0px,15px) rotate(5deg);}30%{-webkit-transform:translate(0,-5px) rotate(0);-ms-transform:translate(0,-5px) rotate(0);transform:translate(0,-5px) rotate(0);}50%{-webkit-transform:translate(-5px,10px) rotate(10deg);-ms-transform:translate(-5px,10px) rotate(10deg);transform:translate(-5px,10px) rotate(10deg);}75%{-webkit-transform:translate(5px,0) rotate(5deg);-ms-transform:translate(5px,0) rotate(5deg);transform:translate(5px,0) rotate(5deg);}}@keyframes scissors-so2mtc8{15%{-webkit-transform:translate(0px,15px) rotate(5deg);-ms-transform:translate(0px,15px) rotate(5deg);transform:translate(0px,15px) rotate(5deg);}30%{-webkit-transform:translate(0,-5px) rotate(0);-ms-transform:translate(0,-5px) rotate(0);transform:translate(0,-5px) rotate(0);}50%{-webkit-transform:translate(-5px,10px) rotate(10deg);-ms-transform:translate(-5px,10px) rotate(10deg);transform:translate(-5px,10px) rotate(10deg);}75%{-webkit-transform:translate(5px,0) rotate(5deg);-ms-transform:translate(5px,0) rotate(5deg);transform:translate(5px,0) rotate(5deg);}}
.s3ag3ey{position:absolute;width:32px;top:0;left:15px;-webkit-animation:scissors-b-s3ag3ey ease-in-out 1.5s infinite;animation:scissors-b-s3ag3ey ease-in-out 1.5s infinite;-webkit-transform-origin:55% 51%;-ms-transform-origin:55% 51%;transform-origin:55% 51%;}@-webkit-keyframes scissors-b-s3ag3ey{0%,33%,100%{-webkit-transform:rotate(8deg);-ms-transform:rotate(8deg);transform:rotate(8deg);}66%{-webkit-transform:rotate(-20deg);-ms-transform:rotate(-20deg);transform:rotate(-20deg);}}@keyframes scissors-b-s3ag3ey{0%,33%,100%{-webkit-transform:rotate(8deg);-ms-transform:rotate(8deg);transform:rotate(8deg);}66%{-webkit-transform:rotate(-20deg);-ms-transform:rotate(-20deg);transform:rotate(-20deg);}}
.s1f95r5v{position:absolute;width:74px;top:22px;left:0;}
.w1u3mlku{position:absolute;width:40px;top:160px;left:190px;-webkit-animation:wool1-w1u3mlku ease-in-out 2.5s 1s infinite;animation:wool1-w1u3mlku ease-in-out 2.5s 1s infinite;-webkit-transform-origin:75px 150px;-ms-transform-origin:75px 150px;transform-origin:75px 150px;}@-webkit-keyframes wool1-w1u3mlku{25%{opacity:1;}50%,100%{-webkit-transform:rotate(120deg);-ms-transform:rotate(120deg);transform:rotate(120deg);opacity:0;}}@keyframes wool1-w1u3mlku{25%{opacity:1;}50%,100%{-webkit-transform:rotate(120deg);-ms-transform:rotate(120deg);transform:rotate(120deg);opacity:0;}}
.w1aub8pu{position:absolute;width:55px;top:240px;left:90px;-webkit-animation:wool2-w1aub8pu linear 3.5s infinite;animation:wool2-w1aub8pu linear 3.5s infinite;}@-webkit-keyframes wool2-w1aub8pu{0%{opacity:0;}10%{-webkit-transform:translate(0,0);-ms-transform:translate(0,0);transform:translate(0,0);}20%{opacity:1;-webkit-transform:translate(-15px,5px);-ms-transform:translate(-15px,5px);transform:translate(-15px,5px);-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out;}35%{opacity:1;}50%,100%{-webkit-transform:rotate(120deg) translate(150px,15px);-ms-transform:rotate(120deg) translate(150px,15px);transform:rotate(120deg) translate(150px,15px);opacity:0;}}@keyframes wool2-w1aub8pu{0%{opacity:0;}10%{-webkit-transform:translate(0,0);-ms-transform:translate(0,0);transform:translate(0,0);}20%{opacity:1;-webkit-transform:translate(-15px,5px);-ms-transform:translate(-15px,5px);transform:translate(-15px,5px);-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out;}35%{opacity:1;}50%,100%{-webkit-transform:rotate(120deg) translate(150px,15px);-ms-transform:rotate(120deg) translate(150px,15px);transform:rotate(120deg) translate(150px,15px);opacity:0;}}
.w1a5vmrb{position:absolute;width:40px;top:165px;left:152px;-webkit-animation:wool3-w1a5vmrb linear 4s 0.5s infinite;animation:wool3-w1a5vmrb linear 4s 0.5s infinite;}@-webkit-keyframes wool3-w1a5vmrb{25%{-webkit-transform:translate(-60px,-100px);-ms-transform:translate(-60px,-100px);transform:translate(-60px,-100px);opacity:1;}50%,100%{-webkit-animation-timing-function:ease-in;animation-timing-function:ease-in;-webkit-transform:translate(-100px,-250px);-ms-transform:translate(-100px,-250px);transform:translate(-100px,-250px);opacity:0;}}@keyframes wool3-w1a5vmrb{25%{-webkit-transform:translate(-60px,-100px);-ms-transform:translate(-60px,-100px);transform:translate(-60px,-100px);opacity:1;}50%,100%{-webkit-animation-timing-function:ease-in;animation-timing-function:ease-in;-webkit-transform:translate(-100px,-250px);-ms-transform:translate(-100px,-250px);transform:translate(-100px,-250px);opacity:0;}}
.waug93t{width:15px;height:15px;border-radius:100%;background-color:#fff;position:absolute;top:185px;left:155px;-webkit-animation:wool4-waug93t linear 4s 0.5s infinite;animation:wool4-waug93t linear 4s 0.5s infinite;-webkit-transform-origin:-300px 600px;-ms-transform-origin:-300px 600px;transform-origin:-300px 600px;}@-webkit-keyframes wool4-waug93t{20%{opacity:1;}40%,100%{-webkit-transform:rotate(-25deg);-ms-transform:rotate(-25deg);transform:rotate(-25deg);opacity:0;}}@keyframes wool4-waug93t{20%{opacity:1;}40%,100%{-webkit-transform:rotate(-25deg);-ms-transform:rotate(-25deg);transform:rotate(-25deg);opacity:0;}}
.w6aoij5{width:25px;height:25px;border-radius:100%;background-color:#fff;position:absolute;top:155px;left:210px;-webkit-animation:wool5-w6aoij5 linear 5s 1s infinite;animation:wool5-w6aoij5 linear 5s 1s infinite;-webkit-transform-origin:-300px -500px;-ms-transform-origin:-300px -500px;transform-origin:-300px -500px;}@-webkit-keyframes wool5-w6aoij5{20%{opacity:1;}40%,100%{-webkit-transform:rotate(-20deg);-ms-transform:rotate(-20deg);transform:rotate(-20deg);opacity:0;}}@keyframes wool5-w6aoij5{20%{opacity:1;}40%,100%{-webkit-transform:rotate(-20deg);-ms-transform:rotate(-20deg);transform:rotate(-20deg);opacity:0;}}
.w1g8a42x{position:absolute;top:160px;left:190px;-webkit-animation:wool6-w1g8a42x linear 3s 2s infinite;animation:wool6-w1g8a42x linear 3s 2s infinite;}@-webkit-keyframes wool6-w1g8a42x{20%{opacity:1;}40%,100%{-webkit-transform:translate(150px,120px) rotate(360deg);-ms-transform:translate(150px,120px) rotate(360deg);transform:translate(150px,120px) rotate(360deg);opacity:0;}}@keyframes wool6-w1g8a42x{20%{opacity:1;}40%,100%{-webkit-transform:translate(150px,120px) rotate(360deg);-ms-transform:translate(150px,120px) rotate(360deg);transform:translate(150px,120px) rotate(360deg);opacity:0;}}
.w16nzext{width:40px;height:40px;border-radius:100%;background-color:#fff;margin-bottom:-20px;}
.w12u5pft{width:40px;height:40px;border-radius:100%;background-color:#fff;}
