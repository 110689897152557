.suz4hac.s1khre1t{-webkit-flex:0 0 50%;-ms-flex:0 0 50%;flex:0 0 50%;height:50%;}
.cqc89w7{position:relative;z-index:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:100%;}
.w14i85sd{width:100%;max-width:800px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:flex-end;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;height:59vh;}
.hd80l7i{display:none;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:10vh;}@media only screen and (min-width:676px){.hd80l7i{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}}
.h1rkipgx{text-align:center;color:#ffffff;}
.k11qz3ct{width:148px;margin-bottom:2em;}
.s1eiczqj{-webkit-flex:1;-ms-flex:1;flex:1;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;max-height:115%;}
.f12xs9fa{padding-top:2vh;-webkit-flex:1;-ms-flex:1;flex:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;min-height:100px;}@media only screen and (max-height:485px){.f12xs9fa{min-height:80px;}}
.bg0yaz9.b1rdc9s4{padding-left:1.5em;padding-right:1.5em;}@media only screen and (max-height:485px){.bg0yaz9.b1rdc9s4{padding-top:10px;padding-bottom:10px;}}
.cqubocm{color:#ffffff;font-size:12px;margin:3vh 0 0;}
.b9wwpv6{position:absolute;top:0;right:0;bottom:0;left:0;background:url(../../../../theme/intro-texture-blue.jpg) repeat 0 0 / 268px auto;pointer-events:none;}.b9wwpv6::after{content:'';position:absolute;top:66.667%;right:0;left:0;bottom:0;background:url(../../../../theme/intro-texture-yellow.jpg) repeat 0 0 / 268px auto;}
