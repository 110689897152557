@font-face {
  font-family: 'Open Sans';
  src: url('fonts/OpenSans-Regular.woff2') format('woff2'),
    url('fonts/OpenSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('fonts/OpenSans-Bold.woff2') format('woff2'),
    url('fonts/OpenSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

html,
body,
body > #root {
  width: 100vw;
  height: 100%;
  min-width: 320px;
  margin: 0;
}

body {
  font-family: 'Open Sans', serif;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  text-align: inherit;

  &:active:not([disabled]) {
    opacity: 0.8;
  }

  &:focus {
    outline: 0;
  }
}
